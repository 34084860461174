@font-face {
  font-family: "Gilroy-medium";
  src: url("/public/assets/fonts/Gilroy-Medium.ttf") format("truetype");
  font-display: swap;
  text-rendering: auto;
  text-rendering: optimizeSpeed;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
}

@font-face {
  font-family: "Gilroy-bold";
  src: url("/public/assets/fonts/Gilroy-Bold.ttf") format("truetype");
  font-display: swap;
  text-rendering: auto;
  text-rendering: optimizeSpeed;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
}

@font-face {
  font-family: "Gilroy-light";
  src: url("/public/assets/fonts/Gilroy-Light.ttf") format("truetype");
  font-display: swap;
  text-rendering: auto;
  text-rendering: optimizeSpeed;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
}
@font-face {
  font-family: "Gilroy-black";
  src: url("/public/assets/fonts/Gilroy-Black.ttf") format("truetype");
  font-display: swap;
  text-rendering: auto;
  text-rendering: optimizeSpeed;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
}
html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  width: 100%;
  background: #0d0d0d;
}
/* ----------General Properties--------------- */
.item-end {
  display: flex;
  justify-content: end;
}
.item-center {
  display: flex;
  align-items: center;
}
.neon {
  color: #abe443;
  font-weight: bold;
}
.pt-60 {
  padding-top: 60px;
}
.mt-100 {
  margin-top: 100px;
}
/* ------------End General Properties------------- */
/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #abe443;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black;
}

/* -----------Navbar--------------- */
.navbar-nav {
  margin-left: auto;
}

.nav-link {
  font-size: 14px;
  font-family: "Gilroy-medium";
  color: white;
  letter-spacing: 0.5px;
  transition: 0.4s ease all;
  position: relative;
  width: max-content;
}

.nav-link:hover {
  color: #abe443;
}

.nav-link::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background: #abe443;
  transition: 0.4s ease all;
}

.nav-link:hover::before {
  width: 100%;
}

.navlinkbtn:hover::before {
  width: 0;
}

.navbar .container {
  position: relative;
}

.navbar {
  background: #0d0d0d;
  padding-left: 5%;
  padding-right: 5%;
  animation: fadeIn 2s;
}

.navul {
  display: contents;
}

.navul li {
  padding-left: 30px;
}
.contact-usbtn {
  background: #abe443;
  border-radius: 8px;
  color: black;
  border: 1px solid #abe443;
}
.contact-usbtn:hover {
  color: white;
  background: transparent;
}
.contact-usbtn:hover::before {
  width: 0 !important;
}
input:focus {
  border: 1px solid #abe443 !important;
}
.nav-link:focus {
  color: #abe443;
}
/* -------------End Navbar-------------- */
/* ------------Landing section--------------- */
#landingsection {
  width: 100%;
  height: 100vh;
  background-image: url("../public/assets/images/landing.png");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  margin-top: -80px;
  z-index: -1;
  color: white;
}
#landingsection .container-fluid {
  display: flex;
  align-items: end;
  padding-left: 6%;
  padding-right: 6%;
  height: 100vh;
  padding-bottom: 5%;
}
.revolvingcircle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.revolvingcircle img {
  width: 130px;
  animation: rotating 30s linear infinite;
}
.revolveicon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 100px;
  transform: translate(-50%, -50%) rotate(45deg);
  color: #abe443;
}
@-webkit-keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.landingcontent-parent h1 {
  font-family: Gilroy-bold;
  text-transform: uppercase;
  position: relative;
  display: inline;
}
.underline-svg {
  position: absolute;
  right: 0%;
  top: 100%;
  transform: translate(0%, -50%);
}
.landingcontent-parent p {
  font-family: Gilroy-light;
  padding-top: 12px;
  padding-right: 15%;
}

.saima-img {
  width: 50%;
  box-shadow: 15px 15px 15px 5px #abe443;
}
/* ------------End Landing section--------------- */
/* ------------HOST SECTION--------------- */
#Host {
  color: white;
  position: relative;
  overflow: hidden;
}
.waves {
  position: absolute;
  top: 5%;
  right: -20px;
  z-index: -1;
}
#Host .container-fluid {
  padding: 10% 6% 2% 6%;
}

.logomain {
  width: 85%;
}
/* -------------End HOST SECTION------------ */
/* ------------ABOUT US SECTION---------------- */
#ABOUT-US {
  color: white;
  position: relative;
  overflow: hidden;
}
#ABOUT-US .container-fluid {
  padding: 1% 6% 2% 6%;
}
/* ---------------END ABOUT SECTION---------------- */
/* ---------------Podcast Section----------------- */
#Podcast {
  color: white;
  position: relative;
  overflow: hidden;
}
#Podcast .container-fluid {
  padding: 6%;
}
.podcast-message {
  display: flex;
  margin-left: auto;
  width: 20%;
  transform: rotate(15deg);
}
.podcastimages {
  position: relative;
  width: 100%;
  transition: 0.4s ease all;
}
.podcastimages:hover {
  /* opacity: 0.3; */
  transform: rotateX(10deg) rotateY(10deg);
}
.podcast-leftparentbox {
  position: relative;
  margin-left: 10%;
  width: 75%;
}
.podcast-rightparentbox {
  margin-left: 15%;
  margin-top: 30%;
  width: 75%;
  position: relative;
}
.pod-imagesgrandparent {
  position: relative;
}
.pod-imagesgrandparent::before {
  content: "";
  position: absolute;
  background-color: #abe443;
  width: 2px;
  height: 0;
  top: 20px;
  right: -20px;
  z-index: -1;
  transition: 0.4s ease all;
}
.pod-imagesgrandparent:hover::before {
  height: 100%;
}
.pod-imagesparent {
  position: relative;
}
.pod-imagesparent::before {
  content: "";
  position: absolute;
  background-color: #abe443;
  width: 0%;
  height: 2px;
  bottom: -20px;
  right: -20px;
  z-index: -1;
  transition: 0.4s ease all;
}
.pod-imagesparent:hover::before {
  width: 100%;
}
.pod-imagesparent::after {
  content: "";
  position: absolute;
  background-color: #abe443;
  width: 2px;
  height: 0;
  top: 20px;
  left: 20px;
  z-index: -1;
  transition: 0.4s ease all;
}
.pod-imagesparent:hover::after {
  height: 100%;
}

.pod-imagesgrandparent::after {
  content: "";
  position: absolute;
  background-color: #abe443;
  width: 0;
  height: 2px;
  top: 20px;
  right: -20px;
  z-index: -1;
  transition: 0.4s ease all;
}
.pod-imagesgrandparent:hover::after {
  width: 20px;
}

.glow-text-loading {
  animation: flicker 2.5s infinite alternate;
  -webkit-text-fill-color: #0d0d0d;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #abe443;
  font-family: "Gilroy-black";
  font-size: 120px;
  letter-spacing: 0.14em;
}
.glow-text {
  animation: flicker 2.5s infinite alternate;
  -webkit-text-fill-color: #0d0d0d;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #abe443;
  font-family: "Gilroy-bold";
  font-size: 120px;
  letter-spacing: 0.14em;
  position: absolute;
  right: 0;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  margin-right: -50px;
  margin-top: 25%;
}
/* Flickering animation */
@keyframes flicker {
  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    text-shadow: 0 0 4px transparent, 0 0 11px transparent, 0 0 19px transparent,
      0 0 2px #abe443, 0 0 4px #abe443, 0 0 6px #abe443, 0 0 8px #abe443,
      0 0 10px #abe443;
  }

  20%,
  24%,
  55% {
    text-shadow: none;
  }
}
.podcast-detail {
  margin-top: 30px;
  font-family: "Gilroy-light";
}
.podcast-detail h2 {
  line-height: 25px;
  font-size: 30px;
}

.back-video {
  width: 5%;
  height: auto;
}
.mic {
  width: 15%;
  height: auto;
  transform: rotate(45deg);
  margin-left: -50px;
}
.mobilenav-img {
  display: none;
}
.moblogbrand {
  display: none;
}
/* -----------------End Podcast Section------------- */

/* -------Marquee--------------- */
#Marquee {
  margin-top: 150px;
  width: 100%;
}
.marquee {
  position: absolute;
  display: block;
  margin: auto auto;
  white-space: nowrap;
  overflow: hidden;
  min-width: 100%;
  height: 100%;
}
.marquee span {
  display: inline-block;
  padding-left: 100%;
  text-align: center;
  white-space: nowrap;
  height: 100%;
  line-height: 85px;
  font-size: 100px;
  animation: marquee 30s linear infinite;
  font-family: "Gilroy-black";
  -webkit-text-fill-color: #0d0d0d;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #abe443;
}
.marquee-w {
  position: relative;
  display: block;
  width: 100%;
  height: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.commnity {
  display: inline;
  -webkit-text-fill-color: #0d0d0d;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #abe443;
  font-size: 100px;
}
.marquee2 {
  padding-left: 20px;
}
.marquee2 span {
  animation-delay: 15s;
}
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}
/* --------------------Footer-------------------- */
.footer {
  color: white;
  font-family: "Gilroy-light";
  padding-bottom: 50px;
  padding: 50px;
}
.form-control:focus {
  box-shadow: none;
}
.row-mobile {
  display: none;
}
.row-desktop {
  display: flex;
}
.social-mediaicons svg {
  color: white;
  font-size: 50px;
  border: 1px solid white;
  padding: 7px;
  border-radius: 10px;
  margin-right: 15px;
  transition: 0.4s ease all;
}
.social-mediaicons svg:hover {
  background-color: #abe443;
  color: black;
  border: 1px solid black;
}
.footer h4 {
  color: #abe443;
  font-weight: 700;
}
.footer-logo {
  padding-top: 50px;
}
input {
  background: transparent !important;
  margin-top: 10px;
  color: white !important;
  transition: 0.4s ease all;
}
input:hover {
  border: 1px solid #abe443 !important;
}
.clickme {
  text-decoration: underline;
}
.submitbtnemail {
  margin-top: 20px;
  padding: 2px 20px;
  border: none;
  border-radius: 8px;
  background-color: #abe443;
  border: 1px solid #abe443;
  transition: 0.4s ease all;
}
.submitbtnemail:hover {
  background-color: transparent;
  color: #abe443;
}
.privacy {
  align-items: end;
  display: flex;
  justify-content: space-around;
}
.privacy a {
  color: white;
  text-decoration: none;
}
.footer-container {
  border: 1px solid #abe443;
  border-radius: 1rem;
  padding: 40px;
}
/* ------------Pre Loader---------- */
#pre-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10;
  background-color: #0d0d0d;
}

#pre-loader video {
  width: 200px;
  height: auto !important;
}

.navbar-brand:nth-child(2) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -35%);
  text-align: center;
}

.logomob {
  display: none;
}

.logomain1 {
  width: 35%;
}

.mobnavsocialicon p {
  margin-top: 44%;
  width: 75%;
  text-align: justify;
  padding-left: 15px;
  font-size: 15px;
}

.btn-close {
  background-image: url("../public/assets/images/cross.png");
  width: 1.5em;
  height: 1.5em;
  background-size: auto;
  margin-left: auto;
  transition: 0.4s ease all;
  border-radius: 50%;
}

.mf-cursor:before {
  background: transparent !important;
  /* border: 1px solid white; */
}

.mf-cursor-text {
  color: white;
}

.mf-cursor-media {
  position: absolute;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  animation: rotating 30s linear infinite;
}
